import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './components/screens/Dashboard/Dashboard'
import Sync from './components/screens/Sync/Sync'
import FilterCreator from './components/screens/Modal/FilterCreator/FilterCreator'
import FilterCreatorGM from './components/screens/Modal/FilterCreator/FilterCreatorGM'
import GenerateWallet from './components/screens/Modal/GenerateWallet/GenerateWallet'
import GenerateCode from './components/screens/Modal/GenerateCode/GenerateCode'
import Modal from './components/screens/Modal/Modal'
import ModalLayout from './components/screens/Modal/ModalLayout'
import ShowBugs from './components/screens/Modal/ShowBugs/ShowBugs'
import ShowFilters from './components/screens/Modal/ShowFilters/ShowFilters'
import ShowFiltersGM from './components/screens/Modal/ShowFilters/ShowFiltersGM'
import Logs from './components/screens/Modal/Logs/Logs'
import Tokens from './components/screens/Modal/Tokens/Tokens'
import AutoBuyCreator from './components/screens/Modal/AutoBuyCreator/AutoBuyCreator'
import ShowBags from './components/screens/Modal/ShowBags/ShowBags'
import ShowBagsReal from './components/screens/Modal/ShowBagsReal/ShowBags'
import EditFilterGM from './components/screens/Modal/EditFilter/EditFilterGM'

// 5452240340

export const router = createBrowserRouter([
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/sync',
    element: <Sync />
  },
  {
    path: '/modal',
    children: [
      {
        element: <Modal />,
        index: true
      },
      {
        element: <ModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <FilterCreator />
          },
          {
            path: 'show-filters',
            element: <ShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <GenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <GenerateCode />
          },
          {
            path: 'logs',
            element: <Logs />
          },
          {
            path: 'show-bags',
            element: <ShowBags />
          },
          {
            path: 'show-bags-real',
            element: <ShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <AutoBuyCreator />
          },
                       {
            path: 'edit-filter-gm',
            element: <EditFilterGM />
          },
             {
            path: 'create-filter-gm',
            element: <FilterCreatorGM />
          },
          {
            path: 'show-filters-gm',
            element: <ShowFiltersGM />
          }

        ]
      }
    ]
  }
])
