export const data = {
    getAllItems() {
        const items = []
        this.options.forEach((option) => items.push(...option.items))
        return items
    },
    options: [{
            id: 1,
            title: 'Contracts',
            items: [{
                    id: 1,
                    name: 'Verified',
                    desc: ''
                },
                {
                    id: 2,
                    name: 'Renounced',
                    desc: ''
                }
            ]
        },
        {
            id: 2,
            title: 'Liquidity',
            items: [{
                    id: 3,
                    name: 'Burned',
                    desc: ''
                },
                {
                    id: 4,
                    name: 'Locked > 1 Month',
                    desc: ''
                },
                {
                    id: 5,
                    name: 'Locked > 3 Months',
                    desc: ''
                },
                {
                    id: 6,
                    name: 'Locked > 6 Months',
                    desc: ''
                },
                {
                    id: 7,
                    name: 'Locked > 1 year',
                    desc: ''
                }
            ]
        },


        {
            id: 3,
            title: 'Snipers',
            items: [{
                    id: 8,
                    name: 'Snipers > 5',
                    desc: ''
                },
                {
                    id: 9,
                    name: 'Snipers > 15',
                    desc: ''
                },
                {
                    id: 10,
                    name: 'Snipers > 25',
                    desc: ''
                },
                {
                    id: 11,
                    name: 'Snipers > 50',
                    desc: ''
                }
            ]
        },
        {
            id: 3,
            title: 'Funded Origin',
            items: [{
                    id: 12,
                    name: 'Exclude Fixed Float',
                    desc: ''
                },
                {
                    id: 13,
                    name: 'Exclude Binance',
                    desc: ''
                },
                {
                    id: 14,
                    name: 'Exclude Coinbase',
                    desc: ''
                },
                {
                    id: 15,
                    name: 'Exclude MEXC',
                    desc: ''
                },
                {
                    id: 16,
                    name: 'Exclude Non Exchanges',
                    desc: ''
                },
            ]
        },
        {
            id: 4,
            title: 'Socials Exists',
            items: [{
                    id: 17,
                    name: 'Telegram',
                    desc: ''
                },
                {
                    id: 18,
                    name: 'Twitter',
                    desc: ''
                },
                {
                    id: 19,
                    name: 'Website',
                    desc: ''
                }
            ]
        },
        {
            id: 5,
            title: 'Misc',
            items: [{
                    id: 20,
                    name: 'Paid Trending',
                    desc: 'Safeguard'
                },
                {
                    id: 27,
                    name: 'Paid Trending',
                    desc: 'BuyTech'
                },
                {
                    id: 28,
                    name: 'Paid Trending',
                    desc: 'Dextools Hot Pairs'
                },
                {
                    id: 21,
                    name: 'Dextools Updated',
                    desc: `Dev has made a payment to Dextools`
                }
            ]
        },
        {
            id: 6,
            title: 'Initial Liquidity',
            items: [{
                    id: 24,
                    name: '> 1 ETH',
                    desc: 'At least 1 ETH initials'
                },
                {
                    id: 25,
                    name: '> 2 ETH',
                    desc: 'At least 2 ETH initials'
                },
                {
                    id: 26,
                    name: '> 3 ETH',
                    desc: 'At least 3 ETH initials'
                }
            ]
        },

    ]
}