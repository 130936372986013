import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import filtersService from '../../services/filters/filters.service'
import { toast } from 'react-toastify'

const initialState = {
  savedQueries: [],
  query: {
    id: nanoid(),
    name: '',
    selected: [],
    telegram_id: 1000498160,
    simulateBuys: false
  },
  channel_member: '',
  a: '',
  noob: '',
  referred: [],
  referral_code: '',
  referCodes: [],

 
  bagsReal: [
    {
      address: 'FVcBzRymz2Ed2mwZfxvUMaWCf3EpL4Yw43AKR8xg8qDH',
      name: 'Roaring Kitty',
      symbol: 'KITTY',
      initial_tokens: '14562.435379173',
      tokens: '14562.435379173',
            initial_price_sol: '0.00000010',      
      current_price_sol: '0.00000020',
      spent: '0.001',
      supply: '14562.435379173',
      initial_liquidity_price: '500.0178',
      initial_price: '0.00000007',
      bag_id: 'LeRsMDawS2wyQMwGCbIPb',
      timestamp: '1718280626',
      autobuy_id: 'pR3fTrZg-bEsaYZn2hnO5',
      wallet_id: 'Zm2fZ4mnM7-IgiIk74dMr',
      current_price: null,
      initial_token_price: '0.00000007',
      current_token_price: '0.00000007',
      filter_id: 'nzvB3t3kbKtNA1-7lKZdh',
      filter_name: '2',
      autosell: null
    },
    {
      address: '9ZuNRVg8CenZjWmmv8rJrYWeTUB7Z6L85mg73v4WpLDY',
      name: 'StockExchange',
      symbol: 'SEX',
      initial_tokens: '29807882673.115',
      tokens: '29807882673.115',
      spent: '0.001',
      supply: '29807882673.115',
      initial_liquidity_price: '16.265508792',
      initial_price_sol: '0.00000010',      
      current_price_sol: '0.00000020',
      bag_id: 'Ia6QooXf8Za4W66hloWvX',
      timestamp: '1718281685',
      autobuy_id: 'pR3fTrZg-bEsaYZn2hnO5',
      wallet_id: 'Zm2fZ4mnM7-IgiIk74dMr',
      current_price: null,
      initial_token_price: '0.00000000',
      current_token_price:  0.00000001,
      filter_id: 'nzvB3t3kbKtNA1-7lKZdh',
      filter_name: '2',
      autosell: null
    }
    ],
  bags: [
    {
id: 372,
telegram_id: '1000498160',
wallet_id: 1,
address: 'BCtn8fmnN9W8UJVT4mgVLfYak97CvBQPecPt7nx5Cgh4',
name: 'NOWIFIwifHAT',
symbol: 'WIFIwifHAT',
amount: '4521.164147361',
spent: '0.001',
supply: '4521.164147361',
marketcap: '13011.160000542232',
filter_id: 45,
initial_amount: '0.001',
timestamp: '1718020236',
initial_price: '0.00000008',
initial_liquidity: '7',
initial_marketcap: '13011.160000542232',
amount_liquidity: '7.822106088',
current_marketcap: '16174.999009100848',
current_token_price: '0.00000010',
filter_name: '2'
},
],
  logs: {
    botLogins: [],
    appLogins: [],
    filterCreations: []
  },
  stats: {
    total: 0,
    active: [],
    inactive: [],
    leaderboard: []
  },
  referrer_address: null,
  autobuy: {
    id: nanoid(),
    filterId: null,
    wallets: [],
    gas: [],
    eth: null
  },

  loading: true,
  telegram_id: 1000498160,
  telegram_username: 'aaaa',
  tier: null,
  cmb_address: null,
  cmb_balance: null,
  cmb_tier: 0,
  sync: {},
  verified: false,
  address: null,
  tokens: null,
  tier: 'og',
    autobuy: {
    id: nanoid(),
    filterId: null,
    wallets: [],
    gas: [],
    eth: null
  },
  "wallets": [],
  autobuyQuery: null,

  user: {
    userId: null
  },
  status: 'idle'
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    /// saved query

    addSavedQuery(state, action) {
      state.savedQueries.push(action.payload)
    },

    editSavedQuery(state, action) {
      state.savedQueries = state.savedQueries.map((sq) =>
        sq.id === action.payload.id ? action.payload : sq
      )
    },

    removeSavedQuery(state, action) {
      state.savedQueries = state.savedQueries.filter(
        (item) => item.id !== action.payload
      )
    },

    deleteBag(state, action) {
      const { id } = action.payload;
      console.log('id', id)
      state.bags = state.bags.filter((bag) => bag.id !== id);
      // console.log('state.bags', state.bags)
    },

    resetQuery(state) {
      state.query = {
        id: nanoid(),
        name: '',
        selected: [],
        telegram_id: null
      }
    },

    editQuery(state, action) {

      state.query = action.payload

    },

    editAutoBuyQuery(state, action) {

      state.autobuy = action.payload

    },

    resetAutobuyQuery(state) {
      state.autobuy = {
        id: nanoid(),
        name: '',
        wallets: [],
        gas: [],
        eth_amount: '',
        max_slippage: '',
        max_gas: ''
      }
    },

    generateCode(state) {
      state.referCodes.push({
        id: nanoid(),
        code: nanoid(),
        users: [
          {
            id: 1,
            name: `Test user`
          }
        ]
      })
    },

    setUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllQueries.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(getAllQueries.fulfilled, (state, action) => {

      if (action.payload.error !== null) {

        toast.success(`ERROR: ${action.payload.error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

      } else {

      state.savedQueries   = action.payload.profiles
      state.autobuys = action.payload.autobuys
      state.wallets = action.payload.wallets
      state.bags = action.payload.bags
      state.bagsReal = action.payload.bagsReal
      state.cmb_address = action.payload.cmb_address
      state.cmb_balance = action.payload.cmb_balance
      state.cmb_tier = action.payload.cmb_tier
      state.tier = action.payload.tier
      state.loading = false
      state.status = 'success'
      
      }

    })

    builder.addCase(addAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(updateAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(deleteAutoBuy.fulfilled, (state, action) => {

      state.savedQueries   = action.payload.data

    })

    builder.addCase(toggleFilter.fulfilled, (state, action) => {
      state.savedQueries = action.payload
      state.status = 'success'
    })
    
    builder.addCase(toggleAutoBuy.fulfilled, (state, action) => {

      state.savedQueries = action.payload
      state.status = 'success'
 
    })

    builder.addCase(getStats.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.logs  = action.payload.logs
      state.stats = action.payload.stats

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(saveReferrer.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      // state.logs  = action.payload.logs
      state.referrer_address = action.payload.referrer_address

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(getTokens.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.tokens = action.payload.tokens

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

function updateData(oldData, newData) {
  return oldData.map(oldItem => {
    const matchingNewItem = newData.find(newItem => newItem.address === oldItem.address);

    if (matchingNewItem) {
      // If there is a matching item in newData, update the price
      return {
        ...oldItem,
        liquidity_price: matchingNewItem.liquidity_price,
      };
    }

    // If no matching item found, return the original item
    return oldItem;
  });
}

    builder.addCase(getBags.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      // state.bags = action.payload.bags
 
      state.bags =     action.payload.bags



      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(getBagsReal.fulfilled, (state, action) => {

      // console.log('action.payload', action.payload)
      state.bagsReal = action.payload.bagsReal
    


      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })


    builder.addCase(sellToken.fulfilled, (state, action) => {

      console.log('action.payload.error', action.payload.error)

      if (action.payload.error !== null) {

        toast.error(`ERROR: ${action.payload.error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

      } else {
        
        toast.success(`SUCCESS!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

        state.bagsReal   = action.payload.bagsReal

      }

    })

    builder.addCase(generateWallet.fulfilled, (state, action) => {

      // console.log('action.payload.wallets', action.payload.wallets)

      state.wallets = action.payload.wallets

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })


    builder.addCase(preVerify.fulfilled, (state, action) => {

      // console.log('action.payload.wallets', action.payload.wallets)

      state.sync = action.payload.sync
      state.cmb_address = action.payload.cmb_address

      /*
      state.savedQueries   = action.payload.data
      state.channel_member = action.payload.channel_member
      state.referral_code = action.payload.referral_code
      state.referred = action.payload.referred
      state.noob = action.payload.noob
      state.tier = action.payload.tier
      state.logs = action.payload.logs
      state.autobuys = action.payload.autobuys
      state.status = 'success'*/
    })

    builder.addCase(addQuery.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(addQuery.fulfilled, (state, action) => {
      state.savedQueries = action.payload
      state.status = 'success'
    })

    builder.addCase(updateQuery.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(updateQuery.fulfilled, (state, action) => {
      state.savedQueries = action.payload

      state.status = 'success'
    })

    builder.addCase(deleteQuery.pending, (state, action) => {
      state.status = 'pending'
    })


    builder.addCase(deleteBagQuery.pending, (state, action) => {
      state.status = 'pending'
    })

    builder.addCase(deleteBagQuery.fulfilled, (state, action) => {
      state.bags = action.payload.bags
      console.log('action.payload', action.payload)
      state.status = 'success'
    })

    builder.addCase(deleteQuery.fulfilled, (state, action) => {
      state.savedQueries = action.payload
      console.log('action.payload', action.payload)
      state.status = 'success'
    })
  }
})

export const getAllQueries = createAsyncThunk('getAllQueries', async (data) =>
  filtersService.getAll(data)
)

export const addQuery = createAsyncThunk('addQuery', async (data) =>
  filtersService.addQuery(data)
)

export const updateQuery = createAsyncThunk('updateQuery', async (data) =>
  filtersService.updateQuery(data)
)

export const deleteQuery = createAsyncThunk('deleteQuery', async (id) =>
  filtersService.deleteQuery(id)
)

export const deleteBagQuery = createAsyncThunk('deleteBagQuery', async (id) =>
  filtersService.deleteBagQuery(id)
)

export const getStats = createAsyncThunk('getStats', async (data) =>
  filtersService.getStats(data)
)

export const getTokens = createAsyncThunk('getTokens', async (data) =>
  filtersService.getTokens(data)
)

export const generateWallet = createAsyncThunk('generateWallet', async (data) =>
  filtersService.generateWallet(data)
)

export const toggleFilter = createAsyncThunk('toggleFilter', async (data) =>
  filtersService.toggleFilter(data)
)

export const addAutoBuy = createAsyncThunk('addAutoBuy', async (data) =>
  filtersService.addAutoBuy(data)
)

export const updateAutoBuy = createAsyncThunk('updateAutoBuy', async (data) =>
  filtersService.updateAutoBuy(data)
)

export const getBags = createAsyncThunk('getBags', async (data) =>
  filtersService.getBags(data)
)

export const getBagsReal = createAsyncThunk('getBagsReal', async (data) =>
  filtersService.getBagsReal(data)
)

export const saveReferrer = createAsyncThunk('saveReferrer', async (data) =>
  filtersService.saveReferrer(data)
)

export const preVerify = createAsyncThunk('preVerify', async (data) =>
  filtersService.preVerify(data)
)

export const toggleAutoBuy = createAsyncThunk('toggleAutoBuy', async (data) =>
  filtersService.toggleAutoBuy(data)
)

export const deleteAutoBuy = createAsyncThunk('deleteAutoBuy', async (id) =>
  filtersService.deleteAutoBuy(id)
)


export const sellToken = createAsyncThunk('sellToken', async (data) =>
  filtersService.sellToken(data)
)