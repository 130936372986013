export const data = {
  getAllItems() {
    const items = []
    this.options.forEach((option) => items.push(...option.items))
    return items
  },



  



  options: [
    {
      id: 1,
      title: 'Authoritive',
      items: [
        {
          id: 1,
          name: 'Mutable',
          desc: ''
        },
        {
          id: 2,
          name: 'Mintable',
          desc: ''
        },
        {
          id: 3,
          name: 'Freezeable',
          desc: ''
        }
      ]
    },
    {
      id: 2,
      title: 'Socials Exist',
      items: [
        {
          id: 4,
          name: 'Telegram',
          desc: ''
        },
        {
          id: 5,
          name: 'Twitter',
          desc: ''
        },
        {
          id: 6,
          name: 'Website',
          desc: ''
        }
      ]
    },


    {
      id: 3,
      title: 'Initial Liquidity',
      items: [
        {
          id: 7,
          name: '> 1 SOL',
          desc: ''
        },
        {
          id: 8,
          name: '> 5 SOL',
          desc: ''
        },
        {
          id: 9,
          name: '> 50 SOL',
          desc: ''
        }
      ]
    },
   
    {
      id: 4,
      title: 'Liquidity',
      items: [
        {
          id: 18,
          name: 'Burned',
          desc: ''
        },
      ]
    },

    {
      id: 5,
      title: 'Misc',
      items: [

        {
          id: 10,
          name: 'Pump Fun Token',
          desc: 'Launched from pump fun'
        },

        {
          id: 16,
          name: 'Paid Trending',
          desc: 'Safeguard'
        },

                {
          id: 19,
          name: 'Paid Trending',
          desc: 'Deluge'
        },

                {
          id: 20,
          name: 'Paid Trending',
          desc: 'Dextools Hot Pairs'
        },


        {
          id: 17,
          name: 'Paid Dextools',
          desc: 'Dev has made a payment to Dextools'
        }
      ]
    },

  ]
}





/*

    1:  { signal_mutable: true },
    2:  { signal_mintable: true },
    3:  { signal_freezeable: true },
    4:  { signal_telegram: true },
    5:  { signal_twitter: true },
    6:  { signal_website: true },
    7:  { signal_initial_liquidity: 1 },
    8:  { signal_initial_liquidity: 5 },
    9: { signal_initial_liquidity: 25 },
    10: { signal_pump: true },
    11: { signal_moonshot: true },

        12: { signal_distro_top5: 10 },
    13: { signal_distro_top10: 10 },
    14: { signal_distro_holders: 10 },
    15: { signal_distro_owner: 10 },


        16: { signal_trending: true },
    17: { signal_dextools: true },
    18: { signal_burned: true }
    */
