import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import styles from './AutoBuyCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './Option/Options'
import Wallets from './Wallet/Wallets'

export default function AutoBuyCreator() {
    const nav = useNavigate()
    const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
    const { addQuery, updateQuery, editAutoBuyQuery, addAutoBuy, updateAutoBuy } = useActions() // api
    const { savedQueries, query, autobuy, user, autobuyQuery } = useSelector((state) => state.modalSlice)
    const { id } = useParams()

    console.log('savedQueries', savedQueries)
    const isEdit = savedQueries.some((sq) => {
        if (sq.autobuy) {
            return sq.autobuy.some((ab) => ab.id === autobuy.id);
        }
        return false;
    });

    console.log('isEdit', isEdit)

    useEffect(() => {

    }, []);


    const saveQuery = () => {

        if (!autobuy.wallets.length) {
            return toast.error('Select a wallet')
        } else if (!autobuy.eth_amount) {
            return toast.error('Add an ETH amount')
        } else if (!autobuy.name) {
            return toast.error('Enter a name')
        } else if (!autobuy.gas.length) {
            return toast.error('Select a gas setting')
        } else if (autobuy.eth_amount < 0.01) {
            return toast.error('ETH amount must be at least 0.01')
        } else if (!autobuy.max_slippage) {
            return toast.error('Select max slippage')
        } else if (!autobuy.max_gas) {
            return toast.error('Select max gas')
        } else if (id == undefined || id === null) {
            return toast.error('Filter ID is empty')
        }



        // nav('/modal/show-filters')


        if (isEdit) {



            editAutoBuyQuery({
                ...autobuy,
                filterId: id
            })

            console.log('update autobuy')
            // console.log('autobuy', autobuy)
            updateAutoBuy({ autobuy, telegram_id: user.telegram_id })
            toast.success('AutoBuy Updated')


        } else {





            editAutoBuyQuery({
                ...autobuy,
                filterId: id
            })

            console.log('save autobuy')
            // console.log('autobuy', autobuy)
            addAutoBuy({ autobuy, telegram_id: user.telegram_id })
            toast.success('AutoBuy Added')


        }
        // resetQuery()
        nav('/modal/show-filters')

    }

    return (
        <div className={styles.autoBuyContainer}>

{/*}
      <div className={s.title}>
        {isEdit ? `Update Autobuy: ${autobuy.name}` : `Create Autobuy on profile: ${autobuy.filterName}`}
      </div>

      */}
      <Wallets />

      <div className={styles.settingsContainer}>

        <div className={styles.settingsCard}>

          <div className={styles.settingsHeader}>  
            <div className={styles.settingsTitle}>SETTINGS</div>
          </div>

          <div className={styles.settingsContainer}>
            
            <div className={styles.settingsRow}>
              
              <div className={styles.value}>MAX GAS</div>
              <div>  
                <input
                  type="text"
                  value={autobuy.max_gas}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, max_gas: event.target.value })}
                  placeholder="30"
                  className={styles.inputField2}
                />
              </div>
            
            </div>

            <div className={styles.settingsRow}>
                
              <div className={styles.value}>MAX SLIPPAGE</div>
              <div> 
                <input
                  type="text"
                  value={autobuy.max_slippage}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, max_slippage: event.target.value })}
                  placeholder="10"
                  className={styles.inputField2}
                />
              </div>
            
            </div>
            
            <div className={styles.settingsRow}>
              
              <div className={styles.value}>ETH AMOUNT</div>
              <div>  
                <input
                  type="text"
                  value={autobuy.eth_amount}
                  onChange={(event) => editAutoBuyQuery({ ...autobuy, eth_amount: event.target.value })}
                  placeholder="0.2"
                  className={styles.inputField2}
                />
              </div>
              
            </div>


            

          
          </div>
        
        </div>
      
    </div>




    <div className={styles.footer}>
    <input
      type="text"
      value={autobuy.name}
      placeholder="AUTOBUY NAME"
      className={styles.inputField}
      onChange={(event) => editAutoBuyQuery({ ...autobuy, name: event.target.value })}
    />
    <button className={styles.saveButton} onClick={saveQuery}>
      {isEdit ? 'Update' : 'SAVE'}
    </button>
  </div>

  </div>
    )
}