import React from 'react';
import s from './ShowFilters.module.scss';
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import { useActions } from '../../../../hooks/use-actions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import robotIcon from './robot.png';
import settingsIcon from './settings.png';
import robot2Icon from './robot2.png';
import { BiX } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'

const ProfileCards = () => {
    const nav = useNavigate()

    const { editQuery } = useActions()

  const { savedQueries = [], user, autobuy, wallets, tier  } = useSelector((state) => state.modalSlice); // Ensures savedQueries is an array by default
  const { deleteQuery, editAutoBuyQuery, deleteAutoBuy, toggleFilter, toggleAutoBuy } = useActions();
  console.log('user', user)
  // console.log('savedQueries', savedQueries)
  const activeQueries = savedQueries.filter(query => !query.deleted);
  // console.log('activeQueries', activeQueries)
  const handleToggle = (item, isPaused) => {
    const data = { id: item.id, telegram_id: item.userId, toggle: isPaused };
    toggleFilter(data);
    toast.success(isPaused ? `Paused ${item.name}` : `Scanning mainnet with ${item.name}`);
  };

  const toggleQuery = (item, bool) => {

    let data = {
        id: item.id,
        telegram_id: user.telegram_id,
        toggle: !item.paused
    }

    toggleFilter(data)



    toast.success(`${item.paused ? `Scanning mainnet with '${item.name}' SIGNAL` : `Paused '${item.name}' SIGNAL`}`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })



  }

  const handleDelete = (id, name) => {

        let data = {
        id,
        telegram_id: user.telegram_id,
        hash: user.hash
    }

    deleteQuery(data)

        toast.success(`Removed '${name}' PROFILE`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
  };

  const removeAutobuyQuery = (item, name) => {

    let data = {
      
      id: item.id,
      telegram_id: user.telegram_id,

    }

   // deleteAutoBuy(data)

        toast.success(`Removed '${data.name}' AUTOBUY`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })

  }

/*
  if (!Array.isArray(savedQueries)) {
    return <p>Error: savedQueries is not an array</p>;
  }
*/
  return (
    <div className={s.container}>
      <div className={s.profilesContainer}>

        {activeQueries.length === 0 ? (
          <p>No profiles found, create your first!</p>
        ) : (
          activeQueries.map((item) => (
            <div key={item.id} className={s.profileCard}>

              


<div className={s.iconCircle}>
      <BiX size={17} color="#fff"  onClick={() => handleDelete(item.id, item.name)} />
    </div>


              <div className={item.autobuy !== undefined && item.autobuy.length > 0 ? s.profileHeader : s.profileHeaderAutobuys}>
                <div className={s.profileTitle}>{item.name}</div>
                <div className={s.iconContainer}>

<img 
  src={robot2Icon} 
  onClick={() => {
    editAutoBuyQuery({
      name: '',
      wallets: [],
      gas: [],
      eth_amount: '',
      max_slippage: '',
      max_gas: '',
      filterId: item.id,
      filterName: item.name
    });
    nav(`/modal/show-filters/${item.id}/auto-buy`);
  }}
  style={{ width: '17px', height: '17px', position: 'relative', bottom: '1px', cursor: 'pointer' }}

/>

              <img                             onClick={() => {
                              editQuery(item)
                              nav('/modal/edit-filter-gm')
                            }} src={settingsIcon} alt="Settings Icon" style={{ width: '16px', height: '16px', position: 'relative', cursor: 'pointer' }} />
            
             


                  <label className={item.paused ? s.toggledOff : s.toggledOn}>
  <input 
    type="checkbox" 
    className={s.toggleInput} 
    checked={!item.paused} 
    onChange={() => toggleQuery(item, !item.paused)} 
  />
  <span className={s.toggleIcon}></span>
</label>


                </div>
              </div>


              <div className={s.profileContainer}>
                {item.autobuy && item.autobuy.map((autoBuyItem, idx) => (




             






                         
            <div key={idx}  className={s.profileRow}>
              <div className={`${s.value} ${s.bag}`}>
                <img  onClick={() => removeAutobuyQuery(autoBuyItem)} src={robot2Icon} alt="Robot2 Icon" style={{ width: '17px', height: '17px', position: 'relative', top: '2px', marginRight: '10px'}} />
                {autoBuyItem.name}
              </div>
              <div className={`${s.value} ${s.highlight}`}>
                <div className={s.iconContainer}>
                   

              <img                                       onClick={() => {
                                        
                                        editAutoBuyQuery(autoBuyItem)

                                        nav(`/modal/show-filters/${item.id}/auto-buy`);

                                      }} src={settingsIcon} alt="Settings Icon" style={{ width: '16px', height: '16px', position: 'relative', }} />




                  <label className={autoBuyItem.paused ? s.toggledOff : s.toggledOn}>
  <input 
    type="checkbox" 
    className={s.toggleInput} 
    checked={!autoBuyItem.paused} 
    onChange={() => toggleAutoBuy(autoBuyItem)} 
  />
  <span className={s.toggleIcon}></span>
</label>

                </div>
              </div>
            </div>



                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProfileCards;
